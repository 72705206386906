import React from 'react'
import "./style_home.css";
import { Link } from 'react-router-dom';

const Home = () => {



    return (
        <>
            <div className="container-fluid bg-black m-0 p-0">
                <div className="row m-0 p-0">
                   
                    <div className="col-md-12 col-sm-12 m-0 p-0 text-icons"><img src="assets/images/topnav.png" alt="" width="100%" style={{ marginTop: "-8px" }} />
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg navbar-light pt-4">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/"><img className="logo" src="assets/images/logo.png" style={{ width: "50%" }} alt="" /></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav mr-auto mb-2 mb-md-0">
                            <li className="nav-item">
                                <a className="nav-link" href="#service">Services</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#Consultancy">Consultancy</a>
                            </li>
                            {/* <li className="nav-item">
                        <a className="nav-link" href="/">Achievements</a>
                    </li>  */}
                            <li className="nav-item">
                                <a className="nav-link" href="#about">About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#contact">Contact</a>
                            </li>
                        </ul>
                        <div className="d-flex nav-btns">
                            <Link className=" nav-btns-1 mr-3" to="/sell">
                                Sell Now
                            </Link>
                            <Link className=" ms-2 nav-btns-2" to="/buy">
                                Buy Now
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>

            <section className="container-fluid head-sec">
                <div className="row">
                    <div className="col-md-6 col-lg-7 col-sm-12 hero-text ">
                        <h1 className="header-text mb-3 ps-2">The Best car <br /> dealership operating<br />in the twin cities</h1>
                        <p className="para mb-4 ps-2">At About cars we offer customer satisfaction whether you <br /> are here to buy
                            your
                            new dream car or sale old one
                        </p>
                        <a className=" ms-2 nav-btns-2" href="#service">
                            Explore Now
                        </a>
                    </div>
                    <div className="col-lg-5 col-sm-12 image p-0 ">
                        <div className='car_hero_image_div'>
                        <img src="https://res.cloudinary.com/dvigo8cs6/image/upload/v1672383581/header_f5cip5.webp" className="img-res img-res-car" width="100%"
                            style={{
                            height: "100%",
                            width: "100%",
                            }}
                          alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-fluid" id="service">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="card container">
                            <img src="assets/images/car.png" className="img-res mb-4" width="45%" alt="..." />
                            <div className="card-body">
                                <h1 className="card-title mb-4">Sell a car</h1>
                                <p className="card-text mb-5">We help you to sell your car by showcasing it at one of our renowned
                                    showrooms
                                    or by simply providing us with pictures
                                    and details of your car to find the best possible buyer for it.</p>
                                <Link className=" nav-btns-1 mr-3" to="/sell">
                                    Sell Now
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="card container">
                            <img src="assets/images/key.png" className="img-res mb-4" width="28%" alt="..." />
                            <div className="card-body">
                                <h1 className="card-title mb-4">Buy a car</h1>
                                <p className="card-text mb-5">We help you to buy your car by showcasing it at one of our renowned
                                    showrooms
                                    or by simply providing us with pictures
                                    and details of your car to find the best possible seller for it.</p>
                                <Link className=" ms-2 nav-btns-2" to="/buy">
                                    Buy Now
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="Consultancy">
                <div className='container2'>
                    <div className="sec-image-3">
                        <img src='https://res.cloudinary.com/dvigo8cs6/image/upload/v1672383721/sec-2_e6lywu.png' className='iconDetails' alt='Secondary ' />
                    </div>
                    <div className="sec-text-2" style={{ marginTop: "180px", marginLeft: "70px" }}>
                        <h1 className="">Buying and Selling <br />Consultancy</h1>
                        <p className="">At About cars we offer customer satisfaction whether you <br /> are here to
                            buy your new dream car or sale old one
                        </p>
                        <a className="btn btn-primary ms-2 nav-btns-2 " href="#service">
                            Explore Now
                        </a>
                    </div>
                </div>
            </section>
            <section>
                <div className="container-fluid">
                    <img src="https://res.cloudinary.com/dvigo8cs6/image/upload/v1672383861/sec-33_ynm7zd.png" className="img-fluid" alt="..." />
                </div>
            </section>
            <section id="about">
                <div className="container-fluid">
                    <img src="https://res.cloudinary.com/dvigo8cs6/image/upload/v1672383776/sec-44_oyjive.webp" className="img-fluid" alt="..." />
                </div>
            </section>
            <section style={{ backgroundColor: "#f9fafc", height: "22vh" }} id="contact">
                <div className="container wrapper-call">
                    <div className="row  pb-3 dis-mobile">
                        <h3 className="col-9 pt-2"><span className="pxs">Want to import a car?</span> <span style={{ fontWeight: 400 }}>
                        </span>
                            <span style={{ fontWeight: 400, fontSize: "16px", padding: "10px 100px", marginTop: "2px" }} className="pxs-2">Our Agents Helps You on Every Step
                            </span>
                        </h3>
                        <div className="col-3 mob-btn">
                            <a className="btn btn-primary ms-2 nav-btns-2 nav-btns-3 " href="/">
                                Call Now
                            </a>
                        </div>
                    </div>
                </div>

            </section>
            <div className="container-fluid">
                <footer className="row" id="footer">
                    <div className="col-md-3 col-sm-12 mb-3">
                        <a href="/" className="d-flex align-items-center justify-content-center mb-3 link-dark text-decoration-none">
                            <img src="assets/images/gray.png" className="me-2" width="80%" alt='Gray' />
                        </a>
                    </div>

                    <div className="col-md-6 col-lg-6 col-sm-12">
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">HEAD OFFICE</a></li>
                            <li className="nav-item mb-2"><a href="https://www.google.com/search?client=opera&q=G-81%2C+Food+Street%2C+Cricket+Stadium+Road%2C+Rawalpindi&sourceid=opera&ie=UTF-8&oe=UTF-8" className="nav-link p-0 text-muted">G-81, Food Street, Cricket Stadium Road, 
                                <br />Rawalpindi</a></li>
                        </ul>
                    </div>


                    <div className="col-md-3 col-sm-12">
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">General</a></li>
                            <li className="nav-item mb-2 d-flex justify-content-center align-items-center">
                                <a href="/" className="nav-link p-0 pr-3  text-muted">
                                    <i
                                        className="fa fa fa-phone "></i></a>0322-6682277</li>
                            <li className="nav-item mb-2">
                                <a href="https://www.gmail.com" className="nav-link p-0  text-muted"> <i className="fa fa-envelope pr-2"></i>
                                    info@gmail.com </a>  </li>
                        </ul>
                    </div>
                </footer>
                <div className="row" style={{ borderTop: "1px solid #f3f4f5" }}>
                    <div className="col-lg-4 col-sm-12 d-flex justify-content-center align-items-center">
                        <p className="text-bottom">Privacy & Cookie Policy Terms of uses</p>
                    </div>
                    <div className="col-lg-4 col-sm-12 d-flex justify-content-center align-items-center">
                        <p className="text-bottom-1">About cars, Copyright {new Date().getFullYear()}</p>
                    </div>
                    <div className="col-lg-4 col-sm-12 d-flex justify-content-center">
                        <div className="d-flex flex-column flex-sm-row justify-content-between mb-4 ">
                            <div className="d-flex flex-column  flex-sm-row justify-content-between">
                            </div>
                            {/* <!-- <div><p className="text-center">&copy; 2022 Company, Inc. All rights reserved.</p></div> --> */}
                            <div>
                                <ul className="list-unstyled d-flex share-icon">
                                    <li className="ms-3"><a className="link-dark" href="https://www.facebook.com/aboutcars.pk/"><i className="fa fa-facebook"></i></a></li>
                                    <li className="ms-3"><a className="link-dark" href="https://www.google.com/search?q=aboutcars+pk&rlz=1C1CHBD_enPK1030PK1030&oq=aboutcars+pk&aqs=chrome..69i57j35i39j69i60l2.7399j0j4&sourceid=chrome&ie=UTF-8"><i className="fa fa-google"></i></a></li>
                                    <li className="ms-3"><a className="link-dark" href="https://mobile.twitter.com/AboutCarsPk"><i className="fa fa-twitter"></i></a></li>
                                    <li className="ms-3"><a className="link-dark" href="https://www.instagram.com/aboutcars.pk/"><i className="fa fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>

    );
}

export default Home;