import React,{useEffect, useState} from "react";
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import "../buy/Modal.css";


// Start of Modal code 


export default function SimpleModal(props) {
  const { value, selectCar, fun,yearFrom,yearTo } = props;
  const [open, setOpen] = React.useState(false);
  const [carsData, setCarsData] = React.useState({makes:[],model:[],version:[]});
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // End of Modal Code

  const handleChange6 = (value, name) => {

    fun(value, name);
  };

  useEffect(() => {
    // eslint-disable-next-line no-array-constructor
    let makes=new Array();
    value.map((item,i)=>{
        
      if(makes.findIndex(m=>{return item.make.toUpperCase()==m.make})===-1){
        makes.push({make:item.make.toUpperCase(),to:item.year.to,from:item.year.from});
      }
      else {
       let index=makes.findIndex(m=>{return item.make.toUpperCase()==m.make});
       if(makes[index].to<item.year.to)
       makes[index].to=item.year.to;
        if(makes[index].from>item.year.from)
          makes[index].from=item.year.from; 
      }
    })


    
    // console.log(makes);
    // eslint-disable-next-line no-array-constructor
    let model=new Array();
    value.map((item,i)=>{
      if(model.findIndex(m=>{return item.model==m.model})===-1){
        model.push({model:item.model,to:item.year.to,from:item.year.from,make:item.make.toUpperCase()});
      }
      else {
        let index=model.findIndex(m=>{return item.model==m.model});
        if(model[index].to<item.year.to)
        model[index].to=item.year.to;
          if(model[index].from>item.year.from)
            model[index].from=item.year.from; 
      }
    })
    let version=new Array();
    // eslint-disable-next-line array-callback-return
    value.map((item,i) =>{
      if(version.findIndex(m=>{return item.version==m.version})===-1){
        version.push({version:item.version,to:item.year.to,from:item.year.from,make:item.make.toUpperCase(),model:item.model});
      }
      else {
        let index=version.findIndex(m=>{return item.version==m.version});
        if(version[index].to<item.year.to)
        version[index].to=item.year.to;
          if(version[index].from>item.year.from)
            version[index].from=item.year.from; 
      }
    })
    setCarsData({makes:makes,model:model,version:version});
    }, [value]);
  
    
    const [searchMake, setSearchMake] = useState("");
    const [searchModel, setSearchModel] = useState("");
    const [searchVersion, setSearchVersion] = useState("");


  // Displaying Make div content 

  const mappingMake = carsData.makes.map(car => { 
    
    if(car.make.substring(0,searchMake.length).toUpperCase()===searchMake.toUpperCase()
    || car.make.substring(0,searchMake.length).toLowerCase()===searchMake.toLowerCase())

    return (
     car? Number(car.to)>=yearFrom && Number(car.from)<=yearTo?
      <div className="p-2 list" lid="year" style={selectCar.make === car.make ? { backgroundColor: "#f5eaec", fontWeight: "bold" } : { backgroundColor: "transparent" }} onClick={() => handleChange6(car.make, "make")}
        key={car.id} id={car.make}
      >
        <div className="d-flex " style={{ width: "300px", paddingLeft: "20px" }}>
          {selectCar.make === car.make ? <img className="align-self-center" width={19} height={19} src="./assets/tick.png" alt="" srcSet="" /> : null}
          <h5 className="pt-1" style={{ marginLeft: "20px" }}>{car.make}</h5>
        </div>
      </div>:null:null
    )
  })

  // Displaying Model div content 
  const mappingModel = carsData.model.map(car => {
    
    if(car.model.substring(0,searchModel.length).toUpperCase()===searchModel.toUpperCase()
    || car.model.substring(0,searchModel.length).toLowerCase()===searchModel.toLowerCase())

    return (
      car?car.make===selectCar.make && Number(car.to)>=yearFrom && Number(car.from)<=yearTo ?
      <div className="p-2 list" lid="year" style={selectCar.model === car.model ? { backgroundColor: "#f5eaec", fontWeight: "bold" } : { backgroundColor: "transparent" }} onClick={() => handleChange6(car.model, "model")}
        key={car.id} id={car.model}
      >
        <div className="d-flex " style={{ width: "300px", paddingLeft: "20px" }}>
          {selectCar.model === car.model ? <img className="align-self-center" width={19} height={19} src="./assets/tick.png" alt="" srcSet="" /> : null}
          <h5 className="pt-1" style={{ marginLeft: "20px" }}>{car.model}</h5>
        </div>
      </div>:null:null
    )
  })

  // Displaying Version div content 
  const mappingVersion = carsData.version.map(car => {

    if(car.version.substring(0,searchVersion.length).toUpperCase()===searchVersion.toUpperCase()
    || car.version.substring(0,searchVersion.length).toLowerCase()===searchVersion.toLowerCase())

    return (
     car?car.model==selectCar.model && car.make===selectCar.make && Number(car.to)>=yearFrom && Number(car.from)<=yearTo ? <div className="p-2 list" lid="version" style={selectCar.version === car.version ? { backgroundColor: "#f5eaec", fontWeight: "bold" } : { backgroundColor: "transparent" }} onClick={() => handleChange6(car.version, "version")}
        key={car.id}
      >
        <div className="d-flex " style={{ width: "300px", paddingLeft: "20px" }}>
          {selectCar.version === car.version ? <img className="align-self-center" width={19} height={19} src="./assets/tick.png" alt="" srcSet="" /> : null}
          <h5 className="pt-1" style={{ marginLeft: "20px" }}>{car.version}</h5>
        </div>
      </div>:null:null
    )
  })





  return (
    <div>
      {
        yearFrom && yearTo ?
        <Button 
          variant="contained" 
          color="primary" 
          style={{ 
            boxShadow: "none", 
            float: "left", display: "flex", 
            justifyContent: "left", 
            fontFamily: " 'poppins', sans-serif !important", 
            background: "#f5f6f7" 
          }} 
          onClick={handleOpen}
      >
        {selectCar.make + ", " + selectCar.model + ", " + selectCar.version}
      </Button>:<Button 
          variant="contained" 
          color="primary" 
          style={{ 
            boxShadow: "none", 
            float: "left", display: "flex", 
            justifyContent: "left", 
            fontFamily: " 'poppins', sans-serif !important", 
            background: "#f5f6f7", 
            opacity: "0.5"
          }} 
          disabled
      >
        Select year range to select car
      </Button>
      }
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >

        <div className="modal-container">
          <div className="container modal-subcontainer">
            <div className="row" id="rowArea">
              <div className="col option border border-right-1" style={selectCar.make !== 'make' ? { background: "#f5f6f7" } : { background: "transparent" }} id="selloption1">
                <h5 className="mt-4 mb-3 fw-bold">Select make</h5>
                <div className="search border border-1 " >
                  <img src="./assets/search.png" className="search-icon" alt="" />
                  <input
                    type="text search-field"
                    placeholder="Search make"
                    className="searchArea pl-2"
                    style={selectCar.make !== 'make' ? { background: "#f5f6f7" } : { background: "transparent" }}
                    onChange={(e) => setSearchMake(e.target.value)}
                  />
                </div>
                <div>
                  {mappingMake}
                </div>
              </div>

              {/* Start of Make Div  */}

              {/* End of Make Div  */}




              {/* Start of Model Div  */}

              {selectCar.make !== 'make' ?
                <div className="col option border border-right-1" style={selectCar.model !== 'model' ? { background: "#f5f6f7" } : { background: "transparent" }} id="selloption3">
                  <h5 className="mt-4 mb-3 fw-bold">Select Model</h5>
                  <div className="search border border-1 rounded">
                    <img src="./assets/search.png" className="search-icon" alt="" />
                    <input type="text search-field" className="searchArea pl-2"
                      placeholder="Search model"
                      style={selectCar.model !== 'model' ? { background: "#f5f6f7" } : { background: "transparent" }}
                      onChange={(e) => setSearchModel(e.target.value)}
                    />
                  </div>
                  <div>
                    <h5 className="mt-3" style={{ fontWeight: "bold" }}>POPULAR</h5>
                    {/* displaying Model  */}
                    {mappingModel}
                  
                  </div>
                </div>
                : null}
              {/* End of Model Div  */}

              {/* Start of Version Div  */}
              {
                selectCar.model !== 'model' ?
                  <div className="col option border border-right-1" id="selloption4">
                    <h5 className="mt-4 mb-3 fw-bold">Select Version</h5>
                    <div className="search border border-1 rounded">
                      <img src="./assets/search.png" className="search-icon" alt="" />
                      <input type="text search-field" className="searchArea pl-2"
                        placeholder="Search version"
                        onChange={(e) => setSearchVersion(e.target.value)}
                      />
                    </div>
                    <div>
                      <h5 className="mt-3" style={{ fontWeight: "bold" }}>POPULAR</h5>
                      {/* displaying Version  */}
                      {mappingVersion}
                    </div>
                  
                  </div> : null
              }

            </div>
            {/* End of Version Div  */}
            <div className="row w-100 py-1">
              <div className="col"></div>
              <div className="col"></div>
              <div className="col button-container">
                <button className="btn border border-top-0 p-2 px-3 m-2 cancel-button mx-4 fw-bolder"
                  style={{ backgroundColor: "#ffebeb", borderRadius: "0px !important", fontWeight: "bold" }} onClick={handleClose}
                >
                  Cancel
                </button>
                <button onClick={handleClose} className="btn btn-danger border border-0 text-light p-2 px-4 fw-bolder submit-btn">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
