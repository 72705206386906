import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "../sell/sell_style.css";
import axios from "axios";
import { useGlobalContext } from "../../context/DataContext";
import { useEffect } from "react";
import {
  Box,
  Button,
  LinearProgress,
  Modal,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" className="flex-column progress_col">
      <h1 className="text-center">We are uploading your data, stay with us!</h1>
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          className="progress_bar_width"
          {...props}
          style={{
            width: "60vw",
            height: "30px",
            background: "#ffe8e9",
            borderRadius: "5px",
            marginTop: "15px",
          }}
        />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{
            fontSize: "24px",
            textAlign: "center",
            fontWeight: "500",
            marginTop: "10px",
          }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff",
  },
});



const SellSubmit = (props) => {
  const [progress, setProgress] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const classes = useStyles();

  const { setHandleNext, setHandleNext2, setDivHandle, divHandle } =
    useGlobalContext();

  const navigate = useNavigate();

  const { data } = props;

  const handleBack = () => {
    setDivHandle(false);
    setHandleNext(true);
    setHandleNext2(true);
    // console.log(divHandle);
  };
  const [uploaded, setUploaded] = useState(false);
  const handleimageupload = async () => {
    let i = 0;

    let uploadedImages = [];
    setOpen(true);
    await data.images.forEach(async (file, index = 0) => {
      let formData = new FormData();
      formData.append("carImages", file);
      axios
        .post(`${process.env.REACT_APP_API_URL}cars/upload-image`, formData,
       {
        headers: {
          'Content-Type': "multipart/form-data"
        }
       }
        )

        .then((res) => {
          if (res.data.data) {
            if (uploadedImages.indexOf(res.data.data) === -1) {
              i++;
              setProgress((i / data.images.length) * 100);
            }
          }
          console.log(res);
          uploadedImages.push(res.data.data);
          if (i === data.images.length) {
            console.log(i);
            onSubmit(uploadedImages);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  useEffect(() => {
    if (uploaded == true) {
      //  console.log(uploadedImages)
      onSubmit();
    }
  }, [uploaded]);
  const onSubmit = async (uploadedImages) => {
    if (data.selectExt === "" || data.selectOption === null) {
      return alert("Please fill all the fields");
    }

    try {
      const json = {
        make: data.selectCar,
        registerCity: data.selectOption,
        exteriorColor: data.selectExt,
        transmission: data.selectTrans,
        milage: data.mileage,
        priority: data.selectPriority,
        availability: data.selectAvailable,
        name: data.contact.name,
        availableDays: data.availableDays,
        email: data.contact.email,
        contactNo: data.contact.number,
        city: data.contact.city,
        address: data.contact.address,
        notes: data.contact.notes,
        carImages: uploadedImages,
      };
      const formData = new FormData();

      formData.append("data", JSON.stringify(json));

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}cars/add-sell`,
        formData
      );
      //   const res = await axios.post(
      //     `http://localhost:8000/cars/add-sell`,
      //     formData
      //   );

      navigate("/successfull");
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  

  return (
    <div>
      <section className="sellSubmit">
        <div className="title-text">Review you request</div>
        <div className="container-fluid wrap-text-111" id="request">
          <div className="wrapper">
            <div className="row mt-5 mb-3">
              <div className="col-lg-1 col-sm-2 text-danger">STEP 1</div>
              <div
                className="col-lg-11 col-sm-10 p-0"
                style={{ fontSize: "18px" }}
              >
                <b>Car Information</b>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-1 col-sm-12">&nbsp;</div>
              <div className="col-lg-4 col-sm-12">
                <div className="sell_submit_content_div">
                  <h4>Make</h4>
                  <p className="text-capitalize">
                    {data.selectCar.year}-{data.selectCar.make}-
                    {data.selectCar.model}-{data.selectCar.version}
                  </p>
                </div>
              </div>
              <div className="col-lg-2 col-sm-12">
                <div className="sell_submit_content_div">
                  <h4>Registration City</h4>
                  <p className="text-capitalize">{data.selectOption}</p>
                </div>
              </div>
              <div className="col-lg-2 col-sm-12">
                <div className="sell_submit_content_div">
                  <h4>Exterior Color</h4>
                  <p>{data.selectExt}</p>
                </div>
              </div>
              <div className="col-lg-2 col-sm-12">
                <div className="sell_submit_content_div">
                  <h4>Transmission</h4>
                  <p className="text-capitalize">{data.selectTrans}</p>
                </div>
              </div>
              <div className="col-lg-1 col-sm-12">
                <div className="sell_submit_content_div">
                  <h4>Mileage</h4>
                  <p>{data.mileage}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <div className="row mt-5 mb-4">
              <div className="col-lg-1 col-sm-2 text-danger">STEP 2</div>
              <div className="col-lg-11 col-sm-10" style={{ fontSize: "18px" }}>
                <b>Priority and availability</b>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-1 col-sm-2 pr-0">&nbsp;</div>
              <div className="col-lg-11 col-sm-10 p-0">
                <div className="sell_submit_content_div">
                  <h4>Priority</h4>
                  <p>{data.selectPriority}</p>
                </div>
              </div>
              <div className="col-lg-1 col-sm-12 pr-0">&nbsp;</div>
              <div className="col-lg-4 col-sm-12 p-0">
                <div className="sell_submit_content_div">
                  <h4>Availability</h4>
                  <p>{data.selectAvailable}</p>
                </div>
              </div>

              {
                data.selectAvailable === "Use my location : Use my location for the offer" && <>
                  <div className="col-lg-3 col-sm-12 p-0">
                <div className="sell_submit_content_div ">
                  <h4 className=" text-lg-center">Available Time</h4>
                  {data.availableDays.map((item, index) => {
                    return (
                      <p className="text-center" key={index}>
                        <p>
                          {item.timeField.from}-{item.timeField.to}
                        </p>
                      </p>
                    );
                  })}
                </div>
              </div>
              
              <div className="col-lg-4 col-sm-12 p-0">
                <div className="sell_submit_content_div">
                  <h4>Available Days</h4>
                  {data.availableDays.map((item, index) => {
                    return (
                      <p style={{ width: "170px" }} key={index}>
                        {item.days.map((item1, index) => {
                          return <span key={index}>{item1},&nbsp;</span>;
                        })}
                      </p>
                    );
                  })}
                </div>
              </div> </>
              }

              
            </div>
          </div>
          <div className="wrapper">
            <div className="wrapper">
              <div className="row mt-5 mb-4">
                <div className="col-lg-1 col-sm-2 text-danger">STEP 3</div>
                <div
                  className="col-lg-11 col-sm-10"
                  style={{ fontSize: "18px" }}
                >
                  <b>Contact Information</b>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-1 col-sm-2 pr-0">&nbsp;</div>
                <div className="col-lg-2 col-sm-12 p-0">
                  <div className="sell_submit_content_div">
                    <h4>Name</h4>
                    <p className="text-capitalize">{data.contact.name}</p>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-12 p-0">
                  <div className="sell_submit_content_div">
                    <h4>Email</h4>
                    <p>{data.contact.email}</p>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-12 p-0">
                  <div className="sell_submit_content_div">
                    <h4>Contact Number</h4>
                    <p>{data.contact.number}</p>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-12 p-0">
                  <div className="sell_submit_content_div">
                    <h4>City</h4>
                    <p className="text-capitalize">{data.contact.city}</p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 p-0">
                  <div className="sell_submit_content_div">
                    <h4>Address</h4>
                    <p>{data.contact.address}</p>
                  </div>
                </div>
              </div>
              <div className="row m-0 my-5 pt-4">
                <div className="col-lg-1 col-sm-12 pr-0">&nbsp;</div>
                <div className="col-lg-11 col-sm-12">
                  <div className="sell_submit_content_div">
                    <h4>Notes</h4>
                    <p >{data.contact.notes}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row p-0">
              <div className="col-lg-1 col-sm-2"></div>
              <div className="col-lg-11 mt-4 mb-4 border-bottom-1 p-0  pt-3 pb-4">
                <span className="text-col " style={{ fontWeight: "600" }}>
                  Kindly review your request, as you won’t be able to make
                  changes once you submit.
                  <br />
                </span>
                <p className="pt-4" style={{ fontSize: "12px !important" }}>
                  We’re excited you’re here and it would be an honour to serve
                  you. Please note that company reserves the right to charge
                  services i.e. 1% of the deal value. <br />
                  By clicking Submit Request, By agreeing or submitting request,
                  you agree to comply with and be bound by these
                  <a href="/" style={{ fontSize: "14px !important" }}>
                    <span className="text-danger large"> terms</span>{" "}
                  </a>{" "}
                  , as applicable to you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec-wrapper mt-3" style={{ height: "120px" }}>
        <hr className="my-4" />
        <div className=" container">
          <div className=" display-wrapper-sell" style={{ paddingLeft: "454px" }}>
            <div className="d-flex nav-btns justify-content-end">
              <button
                className="nav-btns-1 mr-3 text-white"
                onClick={handleBack}
                style={{
                  color: "white !important",
                  background: "#ed1c24",
                  padding: "10px 30px",
                }}
              >
                
                Edit Information
              </button>
              <button
                className="ms-2 nav-btns-2 text-white"
                style={{
                  color: "white !important",
                  background: "#ed1c24",
                  padding: "10px 30px",
                }}
                type="button"
                onClick={handleimageupload}
              >
                Submit Request
              </button>
            </div>
          </div>
        </div>

        {/* <Button onClick={handleOpen}>Open Modal</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.root}>
            <LinearProgressWithLabel value={progress} />
          </div>
        </Modal>
      </section>
    </div>
  );
};

export default SellSubmit;
